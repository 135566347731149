import Header from "./header";
import HamburgerMenu from "./hamburgerMenu";
import SiteTitle from "./siteTitle";

const HeaderComponent = () => {
  return (
    <>
      <div className="appTitle">
        <SiteTitle />
      </div>
      <div className="appHeader">
        <Header />
      </div>
      <HamburgerMenu />
    </>
  );
};
export default HeaderComponent;
