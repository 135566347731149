import React from "react";
import "../../css/pages/about.css";
import HeaderComponent from "../../components/headerComponent";

const About = () => {
  return (

    <>
      <HeaderComponent />


      <div className="aboutWrapper">
        <img
          src="https://cdn.myportfolio.com/2b1b8b85-ad36-43bf-93d1-06269f942d23/c850ba06-6a4e-453c-9908-9fbc52d07144_rw_1200.jpg?h=fe087213fe386aba6b0cd07ecccfb427"
          id="aboutImg"
          alt="Portrait of Lewis Quinn"
        />

        <article>
          <p id="firstPara">
            Lewis Quinn is a director, photographer and videographer based in
            Edinburgh, Scotland. Specialising in adventure-lifestyle and
            landscape photography, Lewis has worked with clients across the UK,
            Europe, and the USA. From humble beginnings photographing trips as
            an enthusiastic teenager, his work has come a long way.{" "}
          </p>

          <p>
            Lewis creates emotion invoking imagery through the use of his
            extensive inventory of professional equipment. He captures stunning
            pictures and videos with Mirrorless cameras and UAVs/drones. The
            media is edited with a clean, consistent style, and can be tailored
            to your branding.
          </p>

          <p>
            The journey of producing visual media has never been easier, thanks
            to Lewis' experience of building Pivotal | the visual media company.
            Founded in 2020, Pivotal can handle clients campaigns from start to
            finish.
          </p>

          <p>
            Starting with Pre-Production, Lewis holds scoping sessions with
            clients to immerse himself with your project and culture. Original,
            engaging stories are created that will captivate and move your
            audience. Lewis can lead and work with teams to create the media,
            before handling post-production editing. Ultimately delivering a
            quality final product.
          </p>

          <p>
            To find out more about Lewis, his work, and to keep up with his day
            to day adventures, why not check out his Instagram? @LEWISQVINN
          </p>

          <p>
            Say hello! Whatever your ideas, I'd would love to hear them! Get in
            touch via phone, or the email and social media links below. Feel
            free to contact me for enquiries, collaborations, or even just a
            chat - I'd love to grab a coffee!{" "}
          </p>
        </article>
      </div>
    </>
  );
};

export default About;
