import "../css/pages/home.css";
import glencoeSnow from "../assets/glencoeSnow.jpeg";

function Home() {
  return (
    <>
      <div className="home-wrapper">
        <div className="home-text">
          <h1> Lewis Quinn</h1>
          <p>Edinburgh based director, photographer, and videographer.</p>
          <a href="/portfolio">
            <button id="view-portfolio-btn">View Portfolio</button>
          </a>
        </div>

        <img
          className="imgLrg"
          src={glencoeSnow}
          alt="mountain landscape"
        ></img>
      </div>
    </>
  );
}

export default Home;
