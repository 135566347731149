import React from "react";
import "../../css/pages/contact.css";
import ContactForm from "../../components/contactForm";
import HeaderComponent from "../../components/headerComponent";

const Contact = () => {
  return (
    <>
      <HeaderComponent />
      <ContactForm />
    </>
  );
};

export default Contact;
