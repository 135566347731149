import React from "react";
import "../../css/pages/pivotal.css";
import pivotalLogo from "../../assets/pivotalLogo.png";
import HeaderComponent from "../../components/headerComponent";

export const GetPivtotalLogo = () => (
  <img
    src={pivotalLogo}
    alt="Logo of Pivotal Media LTD"
    id="pivotal-media-logo"
  />
);

const Pivotal = () => {
  return (
    <>
    <HeaderComponent />
      <div className="pivotalWrapper">
        <GetPivtotalLogo />

        <article>
          <p id="firstPara">Pivotal Media Ltd was founded by Lewis in 2020. </p>

          <p>
            Hailing from Edinburgh, we’re driven by our Scottish roots and
            aren't short of ideas to ensure we fulfil our mission of helping our
            clients build their ideal marketing campaign.
          </p>

          <p>
            Get in touch so that we can kickstart your visual media campaign
            today.
          </p>

          <a href="http://getpivotal.co.uk/">
          <button className="pivotal-btn">Get Pivotal?</button>
          </a>
        </article>
      </div>
    </>
  );
};

export default Pivotal;
