import React from "react";
import HeaderComponent from "../../components/headerComponent";

const PeoplePage = () => {
  return (
    <>
      <HeaderComponent />
      <div className="people-wrapper">
        <article>
          <p id="firstPara">
            This will soon become the People page of my portfolio.{" "}
          </p>
        </article>
      </div>
    </>
  );
};

export default PeoplePage;
