import React from "react";
import "../../css/pages/portfolio.css";
import { Link } from "react-router-dom";
import HeaderComponent from "../../components/headerComponent";

function Portfolio() {
  return (
    <>
      <HeaderComponent />
      <div className="portfolioGridContainer">
        <div className="portfolioMenu">
          <Link to="/landscape" className="portfolioMenuItem" id="landscapeItm">
            <h2>Photography: Landscape and Travel</h2>{" "}
          </Link>

          <Link to="/people" className="portfolioMenuItem" id="peopleItm">
            <h2>Photography: People and Lifestyle</h2>{" "}
          </Link>

          <Link
            to="/commercial"
            className="portfolioMenuItem"
            id="commercialItm"
          >
            <h2>Photography: Commercial</h2>{" "}
          </Link>

          <Link
            to="/videography"
            className="portfolioMenuItem"
            id="videographyItm"
          >
            <h2>Videography</h2>{" "}
          </Link>
        </div>
      </div>
    </>
  );
}

export default Portfolio;
