import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./home";
import About from "../pages/navbar/about";
import Contact from "../pages/navbar/contact";
import Portfolio from "../pages/navbar/portfolio";
import ReactWork from "../pages/navbar/reactWork";
import "../css/components/appRouter.css";
import Pivotal from "../pages/navbar/pivotal";
import CommercialPage from "../pages/portfolio/commercialPage";
import PeoplePage from "../pages/portfolio/peoplePage";
import VideographyPage from "../pages/portfolio/videographyPage";
import LandscapePage from "../pages/portfolio/landscapePage";

// RENAME?

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/pivotal" element={<Pivotal />} />
        <Route path="/reactWork" element={<ReactWork />} />

        <Route path="/landscape" element={<LandscapePage />} />
        <Route path="/people" element={<PeoplePage />} />
        <Route path="/commercial" element={<CommercialPage />} />
        <Route path="/videography" element={<VideographyPage />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
