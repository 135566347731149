import Navbar from "./navbar";
import { useState } from "react";
import "../css/components/hamburgerMenu.css";
import { Squash as Hamburger } from "hamburger-react";

const HamburgerMenu = () => {
 const [isOpen, setOpen] = useState(false);

 if (isOpen) {
    return (
      <>
        <div className="burgerOpenWrapper">
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            rounded
            distance="md"
            duration={0.2}
            easing="ease-in"
            label="Show menu"
          />
          <div id="burgerNav">
            <Navbar />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Hamburger
          toggle={setOpen}
          rounded
          distance="md"
          duration={0.2}
          easing="ease-in"
          label="Show menu"
        />
      </>
    );
  }
};
export default HamburgerMenu;
