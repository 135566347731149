import React from "react";
import "../../css/pages/reactWork.css";
import HeaderComponent from "../../components/headerComponent";

function ReactWork() {
  return (
    <>
      <HeaderComponent />
      <h3>This is the React portfolio page</h3>
    </>
  );
}

export default ReactWork;
